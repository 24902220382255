import React, { useState, useEffect } from 'react'

import { ScheduleComponent, Day, Month, WorkWeek, Inject, DragAndDrop, ViewsDirective, ViewDirective, Agenda, TimelineViews, ResourceDirective, ResourcesDirective, GroupModel, Print } from '@syncfusion/ej2-react-schedule';

import { extend, createElement } from '@syncfusion/ej2-base';
// import { DropDownList } from '@syncfusion/ej2-dropdowns';
import moment from 'moment';
import ApptEditorTemplate from '../SchedulerComponents/ApptEditorTemplate'
// import QuickInfoTemplate from '../SchedulerComponents/QuickInfoTemplate'
// import test from '../SchedulerComponents/test'
import EventTemplateDay from "../SchedulerComponents/EventTemplateDay"
import EventTemplateMonth from '../SchedulerComponents/EventTemplateMonth';
import EventTemplateWeek from "../SchedulerComponents/EventTemplateWeek"
import EventTemplateAgenda from "../SchedulerComponents/EventTemplateAgenda"
import crudOrchestration from '../../../utils/API/crudOrchestration'
import checkForBadEvent from '../../../utils/checkForBadEvent';
import { useSelectedDate } from '../../../utils/Contexts/SelectedDateContext';
import cftokenFns from '../../../utils/helpers/cftokenFns';
import './Styles.css'

// import { type } from 'jquery';
// import { dateTimestampInSeconds } from '@sentry/utils';

import "../../../../node_modules/@syncfusion/ej2-icons/styles/bootstrap5.css";


//
// Notes:
// Validation example: https://stackblitz.com/edit/react-abhv4n-2dhb5n?file=index.js
// Custom fields validation: https://stackblitz.com/edit/react-ne5t4m?file=index.js
// Another / better validation example: https://ej2.syncfusion.com/react/documentation/schedule/editor-template/ see "Field validation"
// Details on form validation: https://ej2.syncfusion.com/documentation/form-validator/#validation-rules
// How to force a refresh (this doesn't seem to work but might be a good refrence): https://www.syncfusion.com/forums/164364/scheduler-viewsdirective-not-responding-to-dynamic-changes-to-starthour

const DocksManagerSchedules = ({ selectedDock, docks, usercode, setUpdatingOverlayStatus, getManagerView, setStatus, refreshData, ownerData, userRole, locationSettings }) => {
    // console.log('DocksManagerSchedules selectedDock', selectedDock)


    const { selectedDate, setSelectedDate } = useSelectedDate()

    // https://ej2.syncfusion.com/react/documentation/schedule/resources/

    let resourceGroupingEnabled = true
    // console.log('DocksManagerSchedules ownerData', ownerData)
    // console.log('DocksManagerSchedules docks', docks)
    // console.log('DocksManagerSchedules events', selectedDock.events)
    let allDocksUseResourcesDirective = false
    if (selectedDock.id === 99999999999999) {
        allDocksUseResourcesDirective = true
    }

    // Comes from UUID validation in useManagerView, getAndEvalManagerView
    let readOnly = true
    if (userRole === 'readwrite') {
        readOnly = false
    }

    let groupData

    if (allDocksUseResourcesDirective) {
        groupData = {
            resources: ['Resources1']
        }
    }

    const cftoken = cftokenFns.get()

    let localDockEvents = []
    if (selectedDock?.events) {
        selectedDock.events.forEach(event => {
            event.ownerId = event.dockId
            localDockEvents.push(event)
        })
        // console.log('DocksManagerSchedules localDockEvents', localDockEvents)
    }

    // Set min and max viewable dates
    // This corisponds to the back end as well so changes here will need to be made there as well
    const today = new Date()
    const sixtyDaysAgo = new Date()
    sixtyDaysAgo.setDate(today.getDate() - 60)
    const threeSixFiveDaysAhead = new Date()
    threeSixFiveDaysAhead.setDate(today.getDate() + 365)


    // Start hour and end our determine when range of hours are visible on the calendar, these can be different than the hours the dock is avialble for carriers to make appointments.  Table userDockSchedulerConfigurations is the place to make those configurations.
    let startHour = "07:00"
    if (locationSettings?.managerViewVisibleHours) {
        startHour = locationSettings.managerViewVisibleHours.start
    } else {
        if (docks[1]?.startHour) {
            startHour = docks[1].startHour
        }
        if (selectedDock.startHour) { startHour = selectedDock.startHour }
    }
    let endHour = "19:00"
    if (locationSettings?.managerViewVisibleHours) {
        endHour = locationSettings.managerViewVisibleHours.end
    } else {
        if (selectedDock.endHour) { endHour = selectedDock.endHour }
        if (docks[1]?.endHour) {
            endHour = docks[1].endHour
        }
    }

    // Work hours are the hours the dock is available for carriers to make appointments.  These may be ifferent than the range available to be visible on the clander determined by startHour and endHour.  These are set on a per dock basis in the docks table.
    let workHours = { highlight: true, start: '07:00', end: '19:00' }
    if (selectedDock.startHour) { workHours.start = selectedDock.startHour }
    if (selectedDock.endHour) { workHours.end = selectedDock.endHour }
    let apptDuration = 30
    if (selectedDock.defaultLength) {
        apptDuration = selectedDock.defaultLength
    }

    let localusercode
    if (usercode) {
        localusercode = usercode
    }
    if (localusercode === 'momcorp' || localusercode === 'kwiktrip') {
        apptDuration = 60
    }

    let selectedDockId = selectedDock.id

    let scheduleObj = {}




    // let ucValidation = (args) => {
    //     console.log('uc validation!')
    //     return false
    // }

    // let minValidation = (args) => {
    //     console.log('minValidation args', args)
    //     return false
    // }

    // Standard Syncfusion form fields like startTime can have their validation settings set hiere.
    // Custom form fields need their validation set inside onPopupOpen as noted here:
    // https://ej2.syncfusion.com/documentation/form-validator/#validation-rules
    let syncFusionFieldsSettings = {
        startTime: { name: 'StartTime', validation: { required: true } },
        endTime: { name: 'EndTime', validation: { required: true } },
        description: { name: 'description' }
    }

    let currentlySelectedDate
    let renderDocksManagerSchedules = (how, args) => {
        // console.log('renderDocksManagerSchedules', how)
        if (how === "renderDocksManagerSchedules") {
            currentlySelectedDate = moment(new Date()).utc(true).format('YYYY-MM-DD')
            let localStorageDate = JSON.parse(localStorage.getItem('schedule'))
            // console.log('localStorageDate', localStorageDate)
            if (localStorageDate) {
                currentlySelectedDate = moment(localStorageDate.selectedDate).utc(true).format('YYYY-MM-DD')

            } else {
                currentlySelectedDate = moment(new Date()).utc(true).format('YYYY-MM-DD')

            }
        }
        if (how === "onChange") {
            currentlySelectedDate = moment(args.currentDate).utc(true).format('YYYY-MM-DD')
            // currentlySelectedDate = moment(new Date()).utc(true).format('YYYY-MM-DD')
            setSelectedDate(new Date(args.currentDate))
        }
    }

    renderDocksManagerSchedules('renderDocksManagerSchedules')

    // useEffect(() => {
    //     console.log('scheduleObj', scheduleObj)
    // }, [docks])


    // 1
    let onCellClick = (args) => {
        // console.log('onCellClick args', args)
        args.endTime = moment(args.startTime).add(apptDuration, 'minutes').toDate()
        args.description = args.subject
        // console.log('cellclick args', args)
    }

    // 2
    let onPopupOpen = (args) => {
        // console.log('onPopupOpen ARGS', args)
        // Not needed as set quick info disabled by default in <ScheduleComponent showQuickInfo={false}
        // if (args.type === "QuickInfo") {
        //     // args.element.ej2_instances[0].hide();
        //     args.cancel = true;
        // }

        //     scheduleObj.openEditor(args.data)

        if (args.type === 'RecurrenceAlert') {
            let editEventBtn = args.element.querySelector('.e-quick-dialog-occurrence-event')
            editEventBtn.style.display = "none"

            let editEventText = args.element.querySelector('#QuickDialog_dialog-content')
            editEventText.innerHTML = "Currently you can create or delete recurring events, but they can not be edited.  <br/><br/>If you wish to change a recurring event, delete the existing recurring event and create a new recurring event for the time needed. <br/><br/> Click 'Entire Series' to continue."
        }

        if (args.type === 'Editor') {
            // let dockIdElement = args.element.querySelector('#dockId');
            // dockIdElement.setAttribute('name', 'dockId');
            // Set default selection for dock dropdown in ApptEditorTemplate.js
            // if (selectedDock.id === 99999999999999) {
            //     dockIdElement.value = docks[1].id
            // } else {
            //     dockIdElement.value = selectedDock.id
            // }

            let typeElement = args.element.querySelector('#type')
            if (typeElement) {
                // Set the default pickup or del event type
                if (localusercode === 'momcorpX' || localusercode === 'kwiktrip') {
                    typeElement.value = "Delivery"
                } else {
                    typeElement.value = "Pickup"
                }
            }
            let formElement = args.element.querySelector('.e-schedule-form');
            formElement.ej2_instances[0].rules = {};
            // console.log('formElement', formElement)
            // if (formElement?.ej2_instances) {
            // let validator = formElement.ej2_instances[0];
            // validator.addRules('dockId', { required: true });
            // } else {
            //     console.log('WAT for some reason didnt find formElement?.ej2_instances')
            // }
        }

        if (args.type === 'DeleteAlert') {
            // No idea how the delete button vanished but at some points it stops appearing, re-displaying it here
            let editEventBtn = args.element.querySelector('.e-quick-dialog-delete')
            editEventBtn.style.display = ""
        }
    }

    function actionComplete(event) {
        // console.log('actionComplete event', event)
        if (event.requestType === 'add' || event.requestType === 'beginEdit') {
            event.form.ej2_instances[0].rules = {};
        }

    }

    // This occurs after you move an appointment, save changes, create a new appointment, NOT on editing an appt
    async function actionBegin(event) {
        // console.log('actionBegin event ', event)
        let newEvent = JSON.parse(JSON.stringify(event))

        // Field names description and Subject get swapped in the AppEditorTemplate, resolving that here for now
        if (newEvent?.data?.Subject && newEvent?.data?.description) {
            newEvent.data.Subject = newEvent.data.description
        }

        if (newEvent?.data) {
            // adding usercode to outgoing events (sometimes usEvent.data is an array, sometimes an object)
            if (Array.isArray(newEvent.data)) {
                newEvent.data[0].usercode = localusercode
                newEvent.data[0].cftoken = cftoken
            } else {
                newEvent.data.usercode = localusercode
                newEvent.data.cftoken = cftoken
            }

        }


        // if (event.requestType === 'dateNavigate') {
        //     console.log('dateNavigation occuring!')
        // }

        // if (newEvent?.data?.uc && newEvent?.data) {
        //     console.log("UC?", newEvent.data[0].uc)
        //     if (newEvent.data.uc === 12) {
        //         alert('WOAH THAT"S A SMALL UC', newEvent.data.uc)
        //     }
        // }


        if (event.requestType === 'dateNavigate') {
            // This block is executed before previous and next navigation
            // console.log('dateNavigate', event)
        }

        // console.log('actionBegin', newEvent)
        // CHANGE an existing appointment
        if (newEvent.requestType === "eventChange") {
            // console.log('eventChange')
            setUpdatingOverlayStatus(true)
            let isBadEvent = await checkForBadEvent(newEvent)
            if (isBadEvent) {
                alert('It looks like you tried creating an event with a start date and time that matches the end date and time.  This is not allowed.')
                setUpdatingOverlayStatus(false)
                refreshData()
                return false
            }
            let result = await crudOrchestration(newEvent, 'manager')
            // console.log('actionBegin crudOrchestration result', result)
            await refreshData()
            setTimeout(() => {
                setUpdatingOverlayStatus(false)
                setStatus(result + JSON.stringify(new Date().getTime()))
            }, 3000);
            // CREATE a new appointment
        } else if (newEvent.requestType === "eventCreate") {
            // console.log('eventCreate')
            setUpdatingOverlayStatus(true)
            let isBadEvent = await checkForBadEvent(newEvent)
            if (isBadEvent) {
                alert('It looks like you tried creating an event with a start date and time that matches the end date and time.  This is not allowed.')
                setUpdatingOverlayStatus(false)
                refreshData()
                return false
            }
            let result = await crudOrchestration(newEvent, 'manager')
            // console.log('actionBegin crudOrchestration result', result)
            await refreshData()
            setTimeout(() => {
                setUpdatingOverlayStatus(false)
                setStatus(result + JSON.stringify(new Date().getTime()))
            }, 3000);
            // DELETE an existing event
        } else if (newEvent.requestType === "eventRemove") {
            setUpdatingOverlayStatus(true)
            let result = await crudOrchestration(newEvent, 'manager')
            // console.log('eventRemove crudOrchestration result', result)
            await refreshData()
            setTimeout(() => {
                setUpdatingOverlayStatus(false)
                setStatus(result + JSON.stringify(new Date().getTime()))
            }, 3000);
        } else if (event.requestType === "toolbarItemRendering") {
            // This happens on startup, the event has a handy list of the appointments and their HTML elements, could be useful but isn't needed at this time
            // console.log('toolbar')

            // Temporarly turning off print due to bug
            // https://www.syncfusion.com/forums/178034/when-printing-event-template-the-printed-page-shows-no-text-or-color-just-an-outline
            // let exportItem = {
            //     align: 'Right', showTextOn: 'Both', prefixIcon: 'e-icon-schedule-print',
            //     text: 'Print', cssClass: 'e-schedule-print', click: onPrintIconClick
            // };
            // event.items.push(exportItem)
        } else {
            console.warn('actionBegin saw a requestType it did not reconginze')
        }
    }

    function onPrintIconClick() {
        // let printModel = {
        //     agendaDaysCount: 14,
        //     cssClass: 'e-print-schedule',
        //     currentView: scheduleObj.currentView,
        //     dateFormat: 'dd-MMM-yyyy',
        //     enableRtl: false,
        //     endHour: '18:00',
        //     firstDayOfWeek: 1,
        //     firstMonthOfYear: 0,
        //     height: 'auto',
        //     locale: scheduleObj.locale,
        //     maxDate: scheduleObj.selectedDate,
        //     minDate: scheduleObj.getCurrentViewDates()[0],
        //     readonly: true,
        //     resources: [],
        //     rowAutoHeight: false,
        //     selectedDate: new Date(),
        //     showHeaderBar: false,
        //     showTimeIndicator: false,
        //     showWeekNumber: false,
        //     showWeekend: false,
        //     startHour: '06:00',
        //     timeFormat: 'HH',
        //     timeScale: { enable: true },
        //     width: 'auto',
        //     workDays: [1, 2, 3, 4, 5],
        //     workHours: { highlight: true, start: '10:00', end: '20:00' }
        // };

        scheduleObj.eventsData = scheduleObj.eventsData.map(event => { event.Subject = event.description; return event })
        scheduleObj.refreshTemplates()
        // console.log('scheduleObj',scheduleObj)
        scheduleObj.print({
            height: 'auto', cssClass: 'e-print-schedule', eventTemplate: null, width: 'auto', eventSettings: {
                dataSource: localDockEvents,
                fields: syncFusionFieldsSettings
            }
        });
    }

    let onDragStart = (args) => {
        if (args.data?.RecurrenceRule && args.data.RecurrenceRule !== "") {
            args.cancel = true
            alert('You may not drag and drop recurring events at this time.  If you wish to change a recurring event please delete the existing recurring event and create a new recurring event.  Thank you.');
        }
    }

    let onNavigate = (args) => {
        // console.log('onNavigation', args)
        renderDocksManagerSchedules('onChange', args)
        if (args.currentDate) {
            setSelectedDate(args.currentDate)
        }

    }

    // let onChange = (args) => {
    //     console.log('onChange')

    // }

    // Could be very handy for later (use enableTooltip: true in eventSettings)
    const tooltipTemplate = (props) => {
        // return null
        console.log('tooltipTemplate props', props)
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        yo
                    </div>
                </div>

            </div>
        )
    }

    let eventSettings = {
        dataSource: localDockEvents,
        fields: syncFusionFieldsSettings,
        enableTooltip: false,
        tooltipTemplate: tooltipTemplate,
    }

    // Set the workDays on the calander (days of the week) (sotred by locationid in userDockSchedulerConfigurations, returned by managerView API call)
    // Default workdays that aren't set in the database are Monday - Friday
    let workDays = [1, 2, 3, 4, 5]
    if (locationSettings?.workDays?.length) {
        workDays = locationSettings.workDays
    }



    // const onEventRendered = (args) => {
    //     console.log('onEventRendered args', args)
    // }

    const onEventRendered = (args) => {
        // console.log('onEventRendered args', args)
        if (args.data.dayCustomColor && args.data.dayCustomColor !== "") {
            // if args.data.dayCustomColor doesn't begin with a # add one
            if (args.data.dayCustomColor[0] !== "#") {
                args.element.style.backgroundColor = "#" + args.data.dayCustomColor
            } else {
                args.element.style.backgroundColor = args.data.dayCustomColor
            }

        }
    };

    if ((selectedDock)) {
        // console.log('ownerData', ownerData)

        return (
            <div>
                <div className="scheduleComponentContainer">
                    <ScheduleComponent
                        readonly={readOnly}
                        height="auto"
                        workDays={workDays}
                        minDate={sixtyDaysAgo}
                        maxDate={threeSixFiveDaysAhead}
                        // delayUpdate="false"
                        // immediateRender="true"
                        showQuickInfo={false}
                        ref={schedule => scheduleObj = schedule}
                        eventSettings={eventSettings}
                        workHours={workHours}
                        cellClick={(event) => onCellClick(event)}
                        editorTemplate={(event) => ApptEditorTemplate(event, selectedDock, docks, localusercode, currentlySelectedDate, ownerData)}
                        popupOpen={(event) => onPopupOpen(event)}
                        actionBegin={(event) => actionBegin(event)}
                        actionComplete={(event) => actionComplete(event)}
                        navigating={(event) => onNavigate(event)}
                        // change={(event) => onChange(event)}
                        // https://ej2.syncfusion.com/react/documentation/schedule/state-persistence/
                        enablePersistence={true}
                        timeScale={{ enable: true, interval: 60, slotCount: localusercode === 'kwiktrip' ? 2 : 4 }}
                        dragStart={(event) => onDragStart(event)}
                        group={resourceGroupingEnabled ? groupData : null}
                        rowAutoHeight={true}
                        eventRendered={onEventRendered}
                    >

                        {resourceGroupingEnabled ? <ResourcesDirective>
                            <ResourceDirective
                                field='dockId'
                                title='Resource Name'
                                name='Resources1'
                                textField='OwnerText'
                                idField='id'
                                colorField='OwnerColor'
                                dataSource={ownerData}
                                allowMultiple={false}
                            >
                            </ResourceDirective>
                        </ResourcesDirective> : null}




                        <ViewsDirective>
                            <ViewDirective option='WorkWeek'
                                startHour={startHour}
                                endHour={endHour}
                                displayName='Week'
                                eventTemplate={(event) => EventTemplateWeek(event, selectedDock)}
                            />

                            <ViewDirective option='Month'
                                startHour={startHour}
                                endHour={endHour}
                                eventTemplate={(event) => EventTemplateMonth(event, selectedDock)}
                            />
                            <ViewDirective option='Day'
                                startHour={startHour}
                                endHour={endHour}
                                isSelected='true'
                                // eventSettings={{ style: { minHeight: '100px' } }}
                                eventTemplate={(event) => EventTemplateDay(event, selectedDock, localusercode, currentlySelectedDate)}
                            />
                            <ViewDirective
                                option='Agenda'
                                startHour={startHour}
                                endHour={endHour}
                                eventTemplate={(event) => EventTemplateAgenda(event, selectedDock)}
                            />
                            <ViewDirective
                                option='TimelineDay'
                                startHour={startHour}
                                endHour={endHour}
                                eventSettings={{ style: { minHeight: '200px' } }}
                                eventTemplate={(event) => EventTemplateDay(event, selectedDock)}
                            />

                        </ViewsDirective>

                        <Inject services={[Day, WorkWeek, Month, DragAndDrop, Agenda, TimelineViews, Print]} />

                    </ScheduleComponent>


                </div>
                {/* </LoadingOverlay> */}

            </div >
        )


    } else {
        return (<div><h3>DocksManagerSchedules Error</h3></div>)
    }
}

export default DocksManagerSchedules
