import React from 'react'

const ModeDropDown = ({ managerMode, setManagerMode }) => {
    if (!managerMode || !setManagerMode) { return null }

    const handleChange = (value) => {
        console.log('event', value)
        console.log(typeof value)
        setManagerMode(value)
    }

    return (
        <div>
            <select class="form-select form-select-sm" onChange={(event) => handleChange(event.target.value)} >

                {managerMode === 'calendar' ? <option selected value="calendar">Calendar</option> : <option value="calendar">Calendar</option>}

                {managerMode === 'unScheduledShipments' ? <option selected value="unScheduledShipments">Unscheduled Shipments</option> : <option value="unScheduledShipments">Unscheduled Shipments</option>}

                {managerMode === 'unapprovedAppointments' ? <option selected value="unapprovedAppointments">Unapproved Appointments</option> : <option value="unapprovedAppointments">Unapproved Appointments</option>}

            </select>
        </div>
    )
}

export default ModeDropDown