import { useState, useEffect } from 'react';


import getManagerView from '../../../utils/API/getManagerView';
import getUUIDValidation from '../../../utils/API/getUUIDValidation';
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";
// 
// Put docks scheduls retrival in here to to ocastrate getting both schedules and events together as they require eachother to filter
// 
const useManagerView = (location, UUID, debug, setPageMode, setUserRole) => {

    let apiCallTimeout = 30000
    let apiCallInterval = 300000
    // let apiCallInterval = 10000

    const history = useHistory();

    const [managerView, setManagerView] = useState([])
    const [docks, setDocks] = useState(null)
    const [unScheduledShipments, setUnScheduledShipments] = useState(null)
    const [usercode, setUsercode] = useState(null)
    const [locationSettings, setLocationSettings] = useState({})
    const [lastUpdateDate, setLastUpdateDate] = useState(null)

    // Action starts here
    useEffect(() => {
        if (location !== null) {

            startUp()
        }
    }, [location]) // eslint-disable-line

    // This only runs on startup 
    async function startUp() {
        if (debug) { console.log('useManagerView startUp()') }
        getAndEvalManagerView('startup', location, UUID, null, setPageMode, setUserRole)
    }

    async function getAndEvalManagerView(mode, location, UUID, lastResults, setPageMode, setUserRole) {
        if (debug) { console.log('useManagerView getManagerView mode:' + mode + " " + new Date()) }

        // UUID validation here, determines if a valid UUID exists and its role
        let UUIDValidationResult = await getUUIDValidation(UUID, location)
        if (UUIDValidationResult?.role) {
            setUserRole(UUIDValidationResult.role)
        } else {
            setPageMode('unauthorized')
            return
        }

        const rawManagerViewFromAPI = await getManagerView(location, apiCallTimeout)

        if (debug) { console.log('useManagerView managerViewFromAPI', rawManagerViewFromAPI) }

        if (rawManagerViewFromAPI.status === "success") {

            let newResults = JSON.stringify(rawManagerViewFromAPI)

            // Just starting up or forcing a refresh
            if (lastResults === null || mode === "startup") {
                if (debug) { console.log('useManagerView existing events are null, assuming we are starting up') }
                let processedManagerViewStartUp = await processManagerViewAndevents(rawManagerViewFromAPI)
                setManagerView(processedManagerViewStartUp)
                setUnScheduledShipments({ pickup: processedManagerViewStartUp.unScheduledPickups, delivery: processedManagerViewStartUp.unScheduledDeliveries })
                setDocks(processedManagerViewStartUp.docks)
                console.log('processedManagerViewStartUp', processedManagerViewStartUp.docks)
                setUsercode(processedManagerViewStartUp.usercode)
                localStorage.setItem('usercode', processedManagerViewStartUp.usercode)
                setLocationSettings(evaluateConfiguration(processedManagerViewStartUp))


            } if (mode === "refresh") {
                if (setPageMode) {
                    setPageMode('updating')
                }
                let processedManagerViewStartUp = await processManagerViewAndevents(rawManagerViewFromAPI)
                setManagerView(processedManagerViewStartUp)
                setUnScheduledShipments({ pickup: processedManagerViewStartUp.unScheduledPickups, delivery: processedManagerViewStartUp.unScheduledDeliveries })
                setDocks(processedManagerViewStartUp.docks)
                setUsercode(processedManagerViewStartUp.usercode)
                localStorage.setItem('usercode', processedManagerViewStartUp.usercode)
                setLocationSettings(evaluateConfiguration(processedManagerViewStartUp))
            } else {
                // Compare if anything changed and act only if the manager view is different
                if (lastResults !== newResults) {
                    setPageMode('updating')
                    // console.log('lastResults', lastResults)
                    // console.log('newResults', newResults)
                    if (debug) { console.log('useManagerView getAndEvalManagerView mode:' + mode + " YES changes!" + new Date()) }
                    let processedManagerViewSawChange = await processManagerViewAndevents(rawManagerViewFromAPI)
                    setManagerView(processedManagerViewSawChange)
                    setUnScheduledShipments({ pickup: processedManagerViewSawChange.unScheduledPickups, delivery: processedManagerViewSawChange.unScheduledDeliveries })
                    setDocks(processedManagerViewSawChange.docks)
                    setUsercode(processedManagerViewSawChange.usercode)
                    localStorage.setItem('usercode', processedManagerViewSawChange.usercode)
                    setLocationSettings(evaluateConfiguration(processedManagerViewSawChange))
                } else {
                    if (debug) { console.log('useManagerView getAndEvalManagerView mode:' + mode + " NO changes! No Action Needed." + new Date()) }
                }
            }

            // If starting up, or a timeout just ran, set the timeout again, but not when just refreshing
            if (mode === "startup" || mode === "regular interval") { getEventsTimeout(location, newResults, apiCallInterval) }
            // setLastUpdateDate(new Date())
        } else {
            getEventsTimeout(location, lastResults, apiCallInterval)
        }

        return true
    }

    function getEventsTimeout(location, lastResults, apiCallInterval) {
        setTimeout(() => { getAndEvalManagerView("regular interval", location, UUID, lastResults, setPageMode, setUserRole) }, apiCallInterval)
    }

    async function refreshData() {
        if (debug) { console.log('useManagerView, you called refreshData', new Date().getTime()) }
        getAndEvalManagerView('refresh', location, UUID, null, setPageMode, setUserRole)
    }

    async function processManagerViewAndevents(rawManagerViewFromAPI) {
        let result = JSON.parse(JSON.stringify(rawManagerViewFromAPI))
        let processedDocks = []
        for (const dock of result.docks) {
            let processedEvents = []
            for (const event of dock.events) {
                // revive js dates into js date format
                event.StartTime = new Date(event.timeIn)
                event.EndTime = new Date(event.timeOut)
                // rename roccurance ruler
                event.RecurrenceRule = event.recurrenceRule
                processedEvents.push(event)
            }
            dock.events = processedEvents
            processedDocks.push(dock)
        }
        result.docks = processedDocks
        return result
    }

    function evaluateConfiguration(processedManagerView) {
        // Sometimes the configuration will not exist for some locations, so we need to check for that here and return an empty object if that is the case
        let configurationSettings = {}
        if (processedManagerView?.locationSettings?.configuration && JSON.parse(processedManagerView.locationSettings.configuration)) {
            configurationSettings = JSON.parse(processedManagerView.locationSettings.configuration)
        }
        return configurationSettings

    }

    return { getManagerView, docks, setDocks, usercode, refreshData, unScheduledShipments, locationSettings, lastUpdateDate }
}

export default useManagerView