import React from 'react'
import { Fade } from 'react-reveal';

const ApptEditorMultiOrderValidationUI = ({ multiShipmentApptModeOrders, multiShipmentApptValidationResults, allOrdersValidatedMessage, noValidationUI }) => {

    if (!multiShipmentApptModeOrders || !multiShipmentApptValidationResults) { return null }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const PickupInfo = ({ shipment }) => {
        if (!shipment) { return null }
        // 
        return (
            <div>
                {shipment.shipper.address.city && shipment.shipper.address.city !== "" ? <>{shipment.shipper.address.city},&nbsp; </> : null}
                {shipment.shipper.address.state && shipment.shipper.address.state !== "" ? <>{shipment.shipper.address.state}&nbsp; </> : null}
                {(shipment.shipper.address.city === "" && shipment.shipper.address.state === "") || (!shipment.shipper.address.city && !shipment.shipper.address.state) ? <><div className='border border-secondary rounded p-1' style={{ backgroundColor: '#e2e3e5' }} >Unknown</div></> : null}
            </div>
        )
    }

    const DeliveryInfo = ({ shipment }) => {
        if (!shipment) { return null }
        // 
        return (
            <div>
                {shipment.consignee.address.city && shipment.consignee.address.city !== "" ? <>{shipment.consignee.address.city},&nbsp; </> : null}
                {shipment.consignee.address.state && shipment.consignee.address.state !== "" ? <>{shipment.consignee.address.state}&nbsp; </> : null}
                {(shipment.consignee.address.city === "" && shipment.consignee.address.state === "") || (!shipment.consignee.address.city && !shipment.consignee.address.state) ? <><div className='border border-secondary rounded p-1' style={{ backgroundColor: '#e2e3e5' }} >Unknown</div></> : null}
            </div>
        )
    }

    const ShipmentInfo = ({ shipment }) => {
        if (!shipment) { return null }
        // if shipment is an empty object
        if (Object.keys(shipment).length === 0 && shipment.constructor === Object) { return null }
        return (
            <>
                <Fade >
                    <div className="card mt-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-4 mb-3">
                                    <strong>{shipment.usercontrol}</strong>
                                </div>
                                <div className="col-sm-8 mb-3">
                                    {allOrdersValidatedMessage.map((message, index) => {
                                        if (message.offender.includes(shipment.usercontrol)) {
                                            return (
                                                <div key={index} className="alert alert-warning border border-warning p-1 text-center" role="alert">
                                                    {message.message}
                                                </div>)
                                        } else { return null }
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5 d-flex justify-content-center">
                                    <PickupInfo shipment={shipment} />
                                </div>
                                <div className="col-sm-2 d-flex justify-content-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                    </svg>
                                </div>
                                <div className="col-sm-5 d-flex justify-content-center">
                                    <DeliveryInfo shipment={shipment} />
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-sm-12">
                                    {shipment.details?.equipment?.length > 0 ? <span>   {shipment.details.equipment.map((equip, index) => {
                                        return (<span key={index}>{capitalizeFirstLetter(equip)}&nbsp;</span>)
                                    })}</span> : null}
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-sm-12">
                                    {shipment.handlingUnits.length > 0 ? <hr /> : <div className='border border-secondary rounded p-1 mt-2' style={{ backgroundColor: '#e2e3e5' }}>No handling unit or item information available for {shipment.id}</div>}

                                    {shipment.handlingUnits.map((hu, index) => {
                                        return (
                                            <div key={index}>
                                                <span>{hu.quantity} {capitalizeFirstLetter(hu.packaging)}</span>
                                                {hu.items.map((item, index) => {
                                                    return (
                                                        <div key={index} className='ms-3'>
                                                            {item.quantity} &nbsp;
                                                            {item.description} &nbsp;
                                                            {item.weightTotal} lbs
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </>
        )
    }

    if (multiShipmentApptModeOrders && multiShipmentApptValidationResults && allOrdersValidatedMessage) {

        let anyMultiShipmentApptModeOrdersAreEmptyObject = false
        for (const order of multiShipmentApptModeOrders) {
            if (Object.keys(order).length === 0 && order.constructor === Object) {
                anyMultiShipmentApptModeOrdersAreEmptyObject = true
            }
        }

        return (
            <>
                {!multiShipmentApptValidationResults.length && !noValidationUI ?
                    <div className="alert alert-success border border-success" role="alert">
                        All orders valid!
                    </div> : null}
                {multiShipmentApptValidationResults.length && !noValidationUI ?
                    <div className="alert alert-warning border border-warning" role="alert">
                        Some orders are invalid! {anyMultiShipmentApptModeOrdersAreEmptyObject ? <><br />One or more orders entered is not a valid order number / was not found.  Below are listed the valid orders.</> : null}
                    </div> : null
                }
                {multiShipmentApptModeOrders.map((shipment, index) => {
                    return (
                        <div key={index}><ShipmentInfo shipment={shipment} /></div>
                    )
                })}
            </>
        )
    } else {
        return null
    }
}

export default ApptEditorMultiOrderValidationUI