import React from 'react'

const DockSelector = ({ docks, selectedDock, setSelectedDock }) => {

    const setTheClickedDock = (dockName) => {

        const clickedDock = docks.filter((dock) => dock.name === dockName)
        if (clickedDock.length > 0) {
            setSelectedDock(clickedDock[0])
        }
    }

    if (docks.length > 1) {
        return (
            <div className="mb-3">

                <div className="d-flex">

                    {docks.map((dock) => {
                        if (dock.name === selectedDock.name) {
                            return (
                                <span key={dock.id} className="flex-fill text-center fs-5 border-bottom border-primary pointerElement" onClick={(event) => setTheClickedDock(event.currentTarget.textContent)}>{dock.name} </span>
                            )
                        } else {
                            return (
                                <span key={dock.id} className="flex-fill text-center pointerElement" onClick={(event) => setTheClickedDock(event.currentTarget.textContent)}>{dock.name}</span>
                            )
                        }
                    })}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default DockSelector
