import React from 'react'
import moment from 'moment'
import ApptOption from './ApptOption/ApptOption'
import ApptOptionTwo from './ApptOptionTwo/ApptOptionTwo'
import Pulse from 'react-reveal/Pulse';

const OpenApptWindowsView = ({ type, openTimeWindows, pickupOrDelivery, existingEvent, shipment, driverCode, getDataNew, adminDate, consolidatedOpenTimeWindows, handleApptBeingScheduled }) => {
    // console.log('openTimeWindows', adminDate, openTimeWindows)
    let consolidatedOpenTimeWindowsMode = true

    if (Object.keys(openTimeWindows).length === 0 && openTimeWindows.constructor === Object) {
        return <div>No open appointments</div>
    }

    if (openTimeWindows && openTimeWindows.length === 0) {
        return <div>No open appointments</div>
    } else if (!pickupOrDelivery) {
        return <div>Unknown Pickup Mode</div>
    } else {
        if (pickupOrDelivery === 'Pickup') {
            if (consolidatedOpenTimeWindowsMode) {

                return (breakoutConsolidatedAppts(type, consolidatedOpenTimeWindows.pickup, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled))
            } else {
                // No longer used by anyone
                return (breakoutAppts(type, openTimeWindows.pickup, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled))
            }


        } else if (pickupOrDelivery === 'Delivery') {
            if (consolidatedOpenTimeWindowsMode) {
                return (breakoutConsolidatedAppts(type, consolidatedOpenTimeWindows.delivery, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled))
            } else {
                // No longer used by anyone
                return (breakoutAppts(type, openTimeWindows.delivery, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled))
            }


        } else {
            return (
                <div>Unknown Pickup Mode 2</div>
            )
        }
    }
},
    breakoutConsolidatedAppts = (type, apptDates, existingEvent, shipment, driverCode, getDataNew, adminDate, handleApptBeingScheduled) => {
        // console.log('openTimeWindows breakoutConsolidatedAppts', apptDates)
        let showDockName = false
        if (apptDates && apptDates.length > 0) {
            // random 6 digit number to use as a key for the accordion (in the case of consolidations there are multiple accordions, do not want the id to be the same)
            let randomKey = Math.floor(Math.random() * 1000000)
            // console.log('randomKey', randomKey)

            let options = apptDates.map((apptDate, index) => {
                let openOrClosed = ''
                let collapsed = 'collapsed'
                if (apptDates.length === 1) {
                    openOrClosed = 'show'
                    collapsed = ''
                }
                let availableAppts = 0
                apptDate.docks.forEach((dock) => {
                    availableAppts = availableAppts + dock.options.length
                })
                let ariaExpanded = 'false'
                if (openOrClosed === 'show') {
                    ariaExpanded = 'true'
                }

                return (
                    <div key={index + randomKey} className="accordion accordion-flush ">
                        <div className="accordion-item border">
                            <h2 className="accordion-header" id={`headder_${index + randomKey}`}>
                                <button className={`accordion-button ${collapsed}`} type="button" data-bs-toggle="collapse" data-bs-target={`#target_${index + randomKey}`} aria-expanded={`target_${ariaExpanded}`} aria-controls={`target_${index + randomKey}`}>
                                    <div className="d-flex justify-content-between w-100">
                                        <div>{moment(apptDate.date.replace(/-/g, '\/').replace(/T.+/, '')).format('dddd MMMM DD, YYYY')}</div>
                                        <div><span className="fw-lighter">({availableAppts} Appointments Available)&nbsp;&nbsp;&nbsp; </span></div>
                                    </div>


                                </button>
                            </h2>

                            <div id={`target_${index + randomKey}`} className={`accordion-collapse collapse ${openOrClosed}`} aria-labelledby={`target_${index + randomKey}`}>
                                <div className="accordion-body">
                                    {apptDate.docks.map((dock, index) => {
                                        dock.options.sort((a, b) => new Date(a.start) - new Date(b.start))
                                        let pastNoon = false
                                        return (
                                            <div className='d-flex flex-wrap justify-content-around'>
                                                {showDockName ? <span>{dock.name}</span> : null}
                                                {dock.options.map((option, index) => {

                                                    if (!pastNoon && moment(option.start).utc(true) > moment(option.start).utc(true).set("hour", 12).set("minute", 0)) {
                                                        // console.log('TEST', moment(option.start).utc(true).set("hour", 12).set("minute", 0).format('LT'))
                                                        pastNoon = true
                                                        return (
                                                            <>
                                                                <ApptOptionTwo
                                                                    key={index}
                                                                    type={type}
                                                                    option={option}
                                                                    existingEvent={existingEvent}
                                                                    shipment={shipment}
                                                                    driverCode={driverCode}
                                                                    getDataNew={getDataNew}
                                                                    adminDate={apptDate.date}
                                                                    handleApptBeingScheduled={handleApptBeingScheduled}
                                                                />
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <Pulse>
                                                                <ApptOptionTwo
                                                                    key={index}
                                                                    type={type}
                                                                    option={option}
                                                                    existingEvent={existingEvent}
                                                                    shipment={shipment}
                                                                    driverCode={driverCode}
                                                                    getDataNew={getDataNew}
                                                                    adminDate={apptDate.date}
                                                                    handleApptBeingScheduled={handleApptBeingScheduled}
                                                                />
                                                            </Pulse>
                                                        )
                                                    }


                                                })}

                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                )
            })
            // console.log('cat', cat)
            return (<div className="m-md-3 mb-3">{options}</div>)
        } else {
            return (
                // bootstrap alert
                <div className='d-flex justify-content-center mt-3'>
                    <div class="alert alert-secondary border border-dark rounded" role="alert" >
                        No appointments available at the specified date(s).
                    </div>
                </div>

            )
        }
    },
    // No longer used by anyone
    breakoutAppts = (type, apptDates, existingEvent, shipment, driverCode, getDataNew, adminDate) => {
        console.warn('breakoutAppts is no longer used by anyone')
        // console.log('openTimeWindows breakoutAppts', apptDates)
        let showDockName = false
        if (apptDates) {

            let options = apptDates.map((apptDate, index) => {
                let openOrClosed = ''
                let collapsed = 'collapsed'
                if (apptDates.length === 1) {
                    openOrClosed = 'show'
                    collapsed = ''
                }
                let availableAppts = 0
                apptDate.docks.forEach((dock) => {
                    availableAppts = availableAppts + dock.options.length
                })
                let ariaExpanded = 'false'
                if (openOrClosed === 'show') {
                    ariaExpanded = 'true'
                }

                return (
                    <div key={index} className="accordion accordion-flush ">
                        <div className="accordion-item border">
                            <h2 className="accordion-header" id={`headder_${index}`}>
                                <button className={`accordion-button ${collapsed}`} type="button" data-bs-toggle="collapse" data-bs-target={`#target_${index}`} aria-expanded={`target_${ariaExpanded}`} aria-controls={`target_${index}`}>
                                    <div className="d-flex justify-content-between w-100">
                                        <div>{moment(apptDate.date.replace(/-/g, '\/').replace(/T.+/, '')).format('dddd MMMM DD, YYYY')}</div>
                                        <div><span className="fw-lighter">({availableAppts} Appointments Available)&nbsp;&nbsp;&nbsp; </span></div>
                                    </div>


                                </button>
                            </h2>

                            <div id={`target_${index}`} className={`accordion-collapse collapse ${openOrClosed}`} aria-labelledby={`target_${index}`}>
                                <div className="accordion-body">
                                    {apptDate.docks.map((dock, index) => {
                                        return (
                                            <div >

                                                {showDockName ? <span>{dock.name}</span> : null}
                                                {dock.options.map((option, index) => {
                                                    return (
                                                        <ApptOption
                                                            key={index}
                                                            type={type}
                                                            option={option}
                                                            existingEvent={existingEvent}
                                                            shipment={shipment}
                                                            driverCode={driverCode}
                                                            getDataNew={getDataNew}
                                                            adminDate={adminDate}
                                                        />
                                                    )
                                                })}

                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                        </div>
                    </div>
                )
            })
            // console.log('cat', cat)
            return (<div className="m-md-3 mb-3">{options}</div>)
        } else {
            return (<p>No appointment dates 3</p>)
        }
    }

export default OpenApptWindowsView