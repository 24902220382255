import React, { useEffect } from 'react'
import SelectSupplier from './SelectSupplier/SelectSupplier'
import ListSelectedSuppliers from './ListSelectedSuppliers/ListSelectedSuppliers'
import IncludeExclude from './IncludeExclude'
import { Fade } from 'react-reveal'

const SupplierWindows = ({ props, localusercode, supplierWindowsMode, setSupplierWindowsMode }) => {

    //     GET https://api.shippersedge.com/schedule/suppliers/?clientcode=kwiktrip 
    // x-api-key: vdhy8wwjUJrwhxFpfuECTiAW

    // fetch suppliers
    // https://api.shippersedge.com/schedule/suppliers/?clientcode=kwiktrip 
    // x-api-key: vdhy8wwjUJrwhxFpfuECTiAW

    const [status, setStatus] = React.useState(null)
    const [suppliers, setSuppliers] = React.useState(null)
    const [selectedSuppliers, setSelectedSuppliers] = React.useState([])
    const [supplierRuleOperator, setSupplierRuleOperator] = React.useState('include')


    // check for props.qualificationRule
    useEffect(() => {
        if (!props.qualificationRule) { return }
        let existingQualificationRule = JSON.parse(props.qualificationRule)
        populateFromExistingSupplierRule(existingQualificationRule)

    }, [props.qualificationRule])


    useEffect(() => {
        if (!localusercode) { return }
        if (supplierWindowsMode) {
            fetchSuppliers(localusercode)
        }
    }, [localusercode, supplierWindowsMode])


    const populateFromExistingSupplierRule = async (existingQualificationRule) => {
        if (!existingQualificationRule) { return }
        if (!existingQualificationRule.ruleType) { return }
        if (!existingQualificationRule.ruleOperator) { return }
        if (!existingQualificationRule.supplierIds) { return }
        try {
            if (existingQualificationRule.ruleType !== 'supplier') { return }
            let fetchedSuppliers = await fetchSuppliers('return')
            setSuppliers(fetchedSuppliers)
            setSupplierRuleOperator(existingQualificationRule.ruleOperator)

            const supplierIdsSplit = existingQualificationRule.supplierIds.split(',')
            const supplierIds = supplierIdsSplit.map(num => parseInt(num.trim(), 10))


            let suppliersObjBasedOnExistingRule = supplierIds.map(id => {
                return fetchedSuppliers.find(supplier => supplier.id === id)
            })

            setSelectedSuppliers(suppliersObjBasedOnExistingRule)

        } catch (error) {
            setStatus('Error populating from existing supplier rule.')
            console.error('populateFromExistingSupplierRule', error)
        }
        setSupplierWindowsMode(true)
    }



    const fetchSuppliers = async (returnOrSet) => {
        setStatus('Fetching suppliers...')
        let clientcode = localusercode
        let suppliers = await fetch(`https://api.shippersedge.com/schedule/suppliers/?clientcode=` + clientcode, {
            method: 'GET',
            headers: {
                'x-api-key': 'vdhy8wwjUJrwhxFpfuECTiAW'
            }
        })
        if (suppliers.status !== 200) {
            setStatus('Error fetching suppliers')
            return
        }

        let data = await suppliers.json()



        if (data.length === 0) {
            setStatus('No suppliers found')
            return
        }
        // const capitalizedSupplierNames = data.map(company => {
        //     return { name: capitalizeWords(company.name) };
        // });

        setStatus(null)
        if (returnOrSet === 'return') {
            return data
        } else {
            setSuppliers(data)
        }

    }


    // function capitalizeWords(str) {
    //     return str
    //         .toLowerCase()
    //         .split(' ')
    //         .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    //         .join(' ');
    // }


    const toggleSupplierWindowMode = () => {
        setSupplierWindowsMode(!supplierWindowsMode)
    }

    if (!props || !localusercode || props.webquotecontrol) { return null }

    return (
        <>

            <div className="row mb-3">
                <div className="col-12">
                    {/* <div onClick={() => toggleSupplierWindowMode()} style={{ cursor: "pointer" }}>Supplier Window&nbsp;
                        <span >{supplierWindowsMode ? <span style={{ textDecoration: 'underline' }}>On</span> : <span style={{ textDecoration: 'underline' }}>Off</span>}</span>
                    </div> */}
                    {/* small checkbox */}
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="supplierWindowsMode" checked={supplierWindowsMode} onChange={() => toggleSupplierWindowMode()} />
                        <label className="form-check-label" htmlFor="supplierWindowsMode">Supplier Window</label>
                    </div>
                    {/* hidden input named qualificationRule */}
                    <input name="qualificationRule" type="text" className="hidden e-field e-input" value='supplier' />
                </div>
            </div>
            <>
                <Fade>
                    <>
                        {supplierWindowsMode ?

                            <div className="row mb-3">
                                <div className="col-12">
                                    <IncludeExclude supplierWindowsMode={supplierWindowsMode} supplierRuleOperator={supplierRuleOperator} setSupplierRuleOperator={setSupplierRuleOperator} />
                                </div>
                            </div>

                            : null}

                        {supplierWindowsMode && suppliers && suppliers.length > 0 ?

                            <div className="row mb-3">
                                <div className="col-12">
                                    <SelectSupplier supplierWindowsMode={supplierWindowsMode} suppliers={suppliers} selectedSuppliers={selectedSuppliers} setSelectedSuppliers={setSelectedSuppliers} />
                                </div>
                            </div>

                            : null}

                        {supplierWindowsMode && selectedSuppliers && selectedSuppliers.length > 0 ?

                            <div className="row mb-3">
                                <div className="col-12">
                                    <ListSelectedSuppliers supplierWindowsMode={supplierWindowsMode} selectedSuppliers={selectedSuppliers} setSelectedSuppliers={setSelectedSuppliers} />
                                </div>
                            </div>

                            : null}

                        {status}
                    </>
                </Fade>
            </>
        </>
    )
}

export default SupplierWindows