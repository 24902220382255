import React, { useState, useEffect } from 'react'
import updateShipment from '../../../utils/API/updateShipment'

const InOutTimes = ({ shipment, appointmentProps, noHR }) => {
    const [delDate, setDelDate] = useState(() => shipment?.consignee?.dates?.actualDelivery || '')
    const [delInTime, setDelInTime] = useState(() => shipment?.consignee?.dates?.actualDeliveryTimeIn || '')
    const [delOutTime, setDelOutTime] = useState(() => shipment?.consignee?.dates?.actualDeliveryTimeOut || '')
    const [componentState, setComponentState] = useState('view')

    useEffect(() => {
        if (shipment?.consignee?.dates?.actualDelivery) setDelDate(shipment?.consignee?.dates?.actualDelivery)
        if (shipment?.consignee?.dates?.actualDeliveryTimeIn) setDelInTime(shipment?.consignee?.dates?.actualDeliveryTimeIn)
        if (shipment?.consignee?.dates?.actualDeliveryTimeOut) setDelOutTime(shipment?.consignee?.dates?.actualDeliveryTimeOut)
    }, [shipment])

    useEffect(() => {
        if (componentState === 'error' || componentState === 'success' || componentState === 'saving') {
            const timer = setTimeout(() => {
                setComponentState('view')
            }, 30000)
            return () => clearTimeout(timer)
        }
    }, [componentState])

    const saveInOutTimes = async () => {
        setComponentState('saving')

        console.log('InOutTimes saveInOutTimes', appointmentProps)
        // break appointmentProps.webquotecontrol into an array of webquotecontrols, broken up by comma

        let shipmentsToUpdate = appointmentProps.webquotecontrol.split(',')
        console.log('InOutTimes saveInOutTimes shipmentsToUpdate', shipmentsToUpdate)

        let response = true

        for (const webquotecontrol of shipmentsToUpdate) {
            let newShipment = {
                id: webquotecontrol,
                consignee: {
                    dates: {
                        actualDelivery: delDate,
                        actualDeliveryTimeIn: delInTime,
                        actualDeliveryTimeOut: delOutTime
                    }
                }
            }
            let individualResponse = await updateShipment(webquotecontrol, newShipment)
            if (individualResponse === null) {
                response = null
            }
        }
        if (response === null) {
            setComponentState('error')
        } else {
            setComponentState('success')
        }
    }

    const success = (
        // green checkbox
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>

    )

    const error = (
        <div className="" role="alert">
            <b><h5>Error!</h5></b>
        </div>
    )

    const savingSpinner = (
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )


    return (
        <>
            <div className="row mb-2">
                <div className="col-12 ">
                    {noHR ? null : <hr />}
                    Actual In / Out:
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 ">
                    <div className="input-group input-group-sm">
                        <input type="date" className="form-control" id="delDate" value={delDate} onChange={(e) => setDelDate(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6">
                    <div className="input-group input-group-sm">
                        <span class="input-group-text" id="inputGroup-sizing-sm">In</span>
                        <input type="time" className="form-control" id="delInTime" value={delInTime} onChange={(e) => setDelInTime(e.target.value)} />
                    </div>
                </div>
                <div className="col-6">
                    <div className="input-group input-group-sm">
                        <span class="input-group-text" id="inputGroup-sizing-sm">Out</span>
                        <input type="time" className="form-control" id="delOutTime" value={delOutTime} onChange={(e) => setDelOutTime(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    {/* flex div that keeps elements in a row */}
                    <div className="d-flex">
                        <div><button className="btn btn-outline-primary btn-sm" onClick={() => saveInOutTimes()}>Save In/Out Date and Times</button></div>
                        <div className="ms-5 d-flex flex-row justify-content-center">
                            {componentState === 'success' ? success : null}
                            {componentState === 'error' ? error : null}
                            {componentState === 'saving' ? savingSpinner : null}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default InOutTimes