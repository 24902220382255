import React, { useState, useEffect } from 'react'
import { Fade } from 'react-reveal'

const DropLiveShipment = ({ role, shipment }) => {

    const [status, setStatus] = useState('start')
    const [liveOrDrop, setLiveOrDrop] = useState(null)

    let debug = false

    useEffect(() => {
        if (shipment) {
            let liveOrDropCF = shipment.customFields.find(cf => cf.fieldID === 10324)
            if (liveOrDropCF) {
                setLiveOrDrop(liveOrDropCF.fieldValue)
            }
        }
    }, [shipment])

    const handleLiveOrDropClick = (choice) => {
        setStatus('loading')
        setLiveOrDrop(choice)
        updateLiveOrDropViaShipmentV4(shipment.id, choice)
    }

    const updateLiveOrDropViaShipmentV4 = async (webquotecontrol, choice) => {
        let url = `https://api.shippersedge.com/v4/shipment/` + webquotecontrol
        let body = {
            forceUpdate: true,
            customfieldValues:
                { field10324: choice }
        }
        let response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-Key': 'vdhy8wwjUJrwhxFpfuECTiAW'
            },
            body: JSON.stringify(body)
        })
        // let data = await response.json()
        // console.log('DropLiveShipment updateLiveOrDropViaShipmentV4 data', data)
        if (response.status === 200) {
            // shipment v4 returns a 200 regardless so checking if it returns the object too here
            let data
            try {
                data = await response.json()
            }
            catch (err) {
                console.error('DropLiveShipment updateLiveOrDropViaShipmentV4 error', err)
            }
            if (data) {
                setStatus('success')
            }

        } else {
            setStatus('error')
        }
    }

    const StatusPannel = () => {
        if (status === 'start' && role === 'carrier') {
            return null
        }
        if (status === 'error') {
            return (
                <div className='text-danger'>
                    Error
                </div>
            )
        }
        if (status === 'success' && role === 'carrier') {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" className="bi bi-check2-circle" viewBox="0 0 16 16">
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg>
            )
        }
        if (status === 'loading' && role === 'carrier') {
            return (
                <div className="spinner-border text-primary" role="status">
                </div>
            )
        } else {
            return null
        }
    }

    if (!role || !shipment) {
        return null
    }

    let disableFade = false
    if (role === 'carrier') {
        disableFade = true
    }

    if (role && shipment) {
        return (
            <Fade disabled={disableFade}>
                <div id="dropLive" className='card mt-2 d-flex flex-row'>
                    <div id="dropLiveInfo" className="card-body ">

                        <div className=" form-check">
                            {
                                liveOrDrop === 'live'

                                    ? <input className="form-check-input" type="radio" name="live" id="live" checked onClick={() => handleLiveOrDropClick('live')} />

                                    : <input className="form-check-input" type="radio" name="live" id="live" onClick={() => handleLiveOrDropClick('live')} />
                            }
                            <label className="form-check-label" for="live">
                                Live {role === "carrier" ? <>Shipment</> : null}
                            </label>
                        </div>


                        <div class="form-check">
                            {
                                liveOrDrop === 'drop'

                                    ? <input className="form-check-input" type="radio" name="drop" id="drop" checked onClick={() => handleLiveOrDropClick('drop')} />

                                    : <input className="form-check-input" type="radio" name="drop" id="drop" onClick={() => handleLiveOrDropClick('drop')} />
                            }
                            <label className="form-check-label" for="drop">
                                Drop {role === "carrier" ? <>Shipment</> : null}
                            </label>
                        </div>


                    </div>
                    <div id="dropLiveStatus" className='d-flex flex-row justify-content-center align-items-center flex-grow-1'>
                        <StatusPannel />
                    </div>
                </div>
            </Fade>
        )
    } else {
        console.warn('DropLiveShipment returning null but should not reach here.')
        return null
    }


}

export default DropLiveShipment