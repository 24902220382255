import * as Sentry from '@sentry/react';


export const createChangeDeleteEventsAPI = async (shipment) => {

    // Retries here are for bad internet connections and timeouts when updates are sent.   If a timeout occurs the return from sendUpdate will be a string "DocksManagerSchedules.js:51 cat DOMException: The user aborted a request"

    try {
        // Sentry loging created due to an incident where some appts were missing and we found a batch of appts were deleleted in a short period of time.  It was found this was due to an api call comming from se TMS and not the client, but the request to log api calls in sentry from the client side remains.  Doing that here.
        // Note that the back end also is logging http requests and can be seeing in GCP under monitoring.
        const eventName = 'createChangeDeleteEventsAPI Dock Scheduler Manager View: ' + shipment.requestType
        Sentry.captureEvent({
            message: `${eventName}`,
            level: 'info',
            extra: {
                arguments: shipment,
            },
        })
    } catch (error) {
        console.error('createChangeDeleteEventsAPI Sentry error', error)
    }


    // CREATE
    if (shipment.requestType === "eventCreate") {
        let firstTry = await sendUpdate(shipment, 10000)
        // console.log('createChangeDeleteEventsAPI first', firstTry)
        if (firstTry.results.affectedRows && firstTry.results.affectedRows === 1) {
            return 'success'
        } else {
            return 'failed'
        }

        // CHANGE
    } else if (shipment.requestType === "eventChange") {
        let firstTry = await sendUpdate(shipment, 10000)
        // console.log('createChangeDeleteEventsAPI first', firstTry)
        if (firstTry.message && firstTry.message.includes('Changed: 1')) {
            return 'success'
        } else {
            return 'failed'
        }
        // DELETE
    } else if (shipment.requestType === "eventRemove") {
        let firstTry = await sendUpdate(shipment, 10000)
        // console.log('createChangeDeleteEventsAPI first', firstTry)
        if (firstTry.message && firstTry.message.includes('Changed: 1')) {
            return 'success'
        } else {
            return 'failed'
        }
    } else {
        console.warn('createChangeDeleteEventsAPI saw a requestType it did not recongize!')
        return 'failed'
    }

    async function sendUpdate(shipment, timeOutValue) {
        // console.log('createChangeDeleteEventsAPI sendUpdate', shipment, timeOutValue)
        return new Promise(function (resolve, reject) {
            let controller = new AbortController();
            var signal = controller.signal;
            let url =
                "https://api.shippersedge.com/schedule/shipment/"

            let myHeaders = new Headers();
            myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
            myHeaders.append("Content-Type", "application/json;charset=UTF-8");
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                signal: signal,
                body: JSON.stringify(shipment)
            };

            let request = new Request(url, requestOptions);

            if (timeOutValue) {
                setTimeout(() => controller.abort(), timeOutValue);
            }
            fetch(request)
                .then((response) => {
                    // console.log(
                    //   "debug createChangeDeleteEventsAPI.js response",
                    //   response
                    // );
                    if (!response.ok) {
                        console.error(
                            "createChangeDeleteEventsAPI.js error",
                            response.statusText,
                            response
                        );
                        throw Error(response.statusText);
                    }
                    // console.log("createChangeDeleteEventsAPI.js", response);
                    return response.json();
                })
                .then((response) => {

                    resolve(response);
                })
                .catch((err) => {
                    console.error("createChangeDeleteEventsAPI API Request failed", err);
                    resolve(err);
                });
        })
    }
};

export default createChangeDeleteEventsAPI