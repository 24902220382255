import React from 'react'
import StatusAlert from './StatusAlert/StatusAlert'


const NavBar = ({ status, docks, userRole }) => {
    let address = null
    if (docks) {
        if (docks[0].name === "All Docks") {
            address = docks[1]
        } else {
            address = docks[0]
        }

    }
    return (

        <div className="d-flex justify-content-center align-items-stretch mt-2 mb-2 container">

            <div className="col-3 d-flex flex-row justify-content-center align-items-center">
                {address ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <span className="xlargeFontSize">{address.dockLocationName}</span><br />
                    </div>
                ) : null}

            </div>



            <div className="col-6">
                {address ?
                    <div style={{ marginTop: '1rem' }}>
                        <img src={"https://www2.shippersedge.com//images/logos/" + address.logo} alt={address.customerName + " logo"} height="60" />
                        <h1 > Dock Scheduler</h1>
                    </div>
                    : null}

            </div>
            {address ? <StatusAlert status={status} address={address} userRole={userRole} /> : null}

        </div >

    )
}

export default NavBar
