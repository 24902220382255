import React, { useState } from 'react'
import getShipmentWebq from '../../../../utils/API/getShipmentWebq'
import DetailsWrapper from './DetailsWrapper'
import InOutTimes from '../InOutTimes'
import Colors from '../Colors'


const DetailsAndModal = ({ appointmentProps }) => {
  const [shipment, setShipment] = useState(null)

  if (!appointmentProps) return null
  if (!appointmentProps.webquotecontrol) return null

  let localusercode = localStorage.getItem('usercode')

  const pointerStyle = {
    cursor: 'pointer'
  }

  const clickGetShip = async () => {
    if (!shipment) {
      let result = await getShipmentWebq(appointmentProps.webquotecontrol, 'all')
      setShipment(result)
    }
    return true
  }

  let detailsModalId = 'detailsModal' + appointmentProps.id
  return (
    <div key={appointmentProps.id}>
      <div onClick={() => clickGetShip()} onMouseEnter={() => clickGetShip()}>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="black" className="bi bi-card-list mt-2 border border-dark rounded" viewBox="0 0 16 16" style={pointerStyle} data-bs-toggle="modal" data-bs-target={`#${detailsModalId}`}>
          <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2m0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
        </svg>
      </div>

      <div class="modal fade" id={detailsModalId} tabindex="-1" aria-labelledby="detailsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="detailsModalLabel">Shipment Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <DetailsWrapper appointmentProps={appointmentProps} shipment={shipment} />
              <div className="row">
                <div className="col-6">
                  <div className="mt-3" style={{ width: 350 }}>
                    {shipment && (localusercode === 'momcorp' || localusercode === 'kwiktrip') ? <InOutTimes shipment={shipment} appointmentProps={appointmentProps} noHR={true} /> : null}
                  </div>
                </div>
                {/* <div className="col-6">
                  <div className="mt-3" style={{ width: 350 }}>
                    {shipment && (localusercode === 'momcorp' || localusercode === 'kwiktrip') ? <Colors shipment={shipment} appointmentProps={appointmentProps} noHR={true} /> : null}
                  </div>
                </div> */}
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsAndModal